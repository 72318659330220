import React from 'react';

function Logo() {
      /* return (<div
            style={{ backgroundImage: `url("../images/clb-blue.svg")` , height: '58px', width: '58px', flex: '0 0 auto' }}
            className='headerLogo'
          />) */
      return (<img src="/image26.png" style={{ height: '54px', flex: '0 0 auto' }} alt=""></img>);
}

export default Logo;